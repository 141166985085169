<template>
  <div>
    <b-row no-gutters>
      <b-col cols="auto">
        <h4>Configuração de Permissões
          <!-- <feather-icon
            v-if="!isAdm"
            color="#008000"
            icon="LockIcon"
            size="16"
          />
          <feather-icon
            v-else
            color="#FF0000"
            icon="UnlockIcon"
            size="16"
          /> -->

        </h4>

      </b-col>
      <b-col class="ml-5">
        <b-row>
          <b-form-checkbox
            v-b-tooltip.hover
            title="Ao marcar um usuário como administrador ele passa a ter acesso ilimitado ao sistema, visualizando todas as empresas cadastradas na base."
            :checked="isAdm"
            switch
            @change="administradorSelecionado($event)"
          />
          <span
            class="d-inline-flex align-items-center"
            style="font-size: 0.857rem;"
          >
            Marcar como administrador
          </span>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <fieldset class="font-weight-bold text-muted small">
          Marque ou desmarque as permissões desejadas
        </fieldset>
        <hr
          class="mx-0 mt-0 mb-2"
          style="border-top: 1px solid #a3a4a66b !important;"
        >
      </b-col>
    </b-row>
    <grupo-permissao
      v-for="(grupo, $index) in grupos"
      :key="$index"
      :recursos="grupo.recursos"
      :grupo="grupo.grupo"
      :is-adm="isAdm"
      :selecionados="recursos"
      @check="selecionadoRecurso"
      @checkAll="selecionadoGrupo"
    />
  </div>
</template>

<style lang="scss"></style>

<script>
import api from '@/app/usuario/shared/services/api'

export default {
  name: 'ConfigurarPermissoes',
  components: {
    GrupoPermissao: () => import('./GrupoPermissao.vue'),
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    administrador: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAdm: false,
      state: 'none',
      recursos: [],
      grupos: this.getPermissao(),
    }
  },
  watch: {
    value(val, oldVal) {
      if (val !== oldVal) {
        this.recursos = val
      }
    },
    administrador(val, oldVal) {
      if (val !== oldVal) {
        this.isAdm = val
        this.administradorSelecionado(this.isAdm)
      }
    },
  },
  created() {
    this.recursos = []
    if (this.value && this.value.length > 0) {
      this.recursos.push(this.value)
    }
  },
  methods: {
    getPermissao() {
      const permissoes = localStorage.getItem('permissoes')
      const gruposRecursos = [
        {
          grupo: { nome: 'dashboard', descricao: 'Dashboard' },
          recursos: permissoes.includes('dashboard_analitico') ? [{ nome: 'dashboard_analitico', descricao: 'Analítico' }] : [],
        },
        {
          grupo: { nome: 'federal', descricao: 'Federal' },
          recursos: [
            { nome: 'situacaofiscal_federal', descricao: 'Situação Fiscal' },
            { nome: 'declaracao_dctfweb', descricao: 'DCTFWeb' },
            { nome: 'comprovante_pagamento', descricao: 'Comprovante de arrecadação e-CAC' },
          ].filter(recurso => permissoes.includes(recurso.nome)),
        },
        {
          grupo: { nome: 'certidoes', descricao: 'Certidões' },
          recursos: [
            { nome: 'cnd_federal', descricao: 'RFB/PGFN' },
            { nome: 'cnd_fgts', descricao: 'FGTS' },
            { nome: 'cnd_trabalhista', descricao: 'Trabalhista' },
            // { nome: 'cnd_mte', descricao: 'MTE' },
            { nome: 'cnd_estadual', descricao: 'Estadual' },
            { nome: 'cnd_municipal', descricao: 'Municipal' },
          ].filter(recurso => permissoes.includes(recurso.nome)),
        },
        {
          grupo: { nome: 'caixa_postal', descricao: 'Caixa postal' },
          recursos: [
            { nome: 'caixapostal_ecac', descricao: 'Mensagens e-Cac' },
            // { nome: 'caixapostal_estadual', descricao: 'Estadual' } // Comentado
          ].filter(recurso => permissoes.includes(recurso.nome)),
        },
        {
          grupo: { nome: 'parcelamento', descricao: 'Parcelamentos' },
          recursos: [
            { nome: 'das_parcelamento', descricao: 'Simples Nacional' },
            { nome: 'parcelamento_pertsn', descricao: 'PERT SN' },
            { nome: 'parcelamento_relpsn', descricao: 'RELP SN' },
            { nome: 'parcelamento_rfb', descricao: 'RFB' },
            { nome: 'parcelamento_naoprevidenciario', descricao: 'Não Previdenciário' },
            { nome: 'pgfn_parcelamento', descricao: 'PGFN' },
            { nome: 'parcelamento_simp_previdenciario', descricao: 'Simplificado Previdenciário' },
          ].filter(recurso => permissoes.includes(recurso.nome)),
        },
        {
          grupo: { nome: 'simplesNacional', descricao: 'Simples Nacional' },
          recursos: [
            { nome: 'das', descricao: 'DAS' },
            { nome: 'sublimite', descricao: 'Sublimite' },
          ].filter(recurso => permissoes.includes(recurso.nome)),
        },
        {
          grupo: { nome: 'documentos', descricao: 'Documento' },
          recursos: [
            { nome: 'portal_documentos', descricao: 'Portal' },
            { nome: 'cnd_portal_documentos', descricao: 'CNDs' },
          ].filter(recurso => permissoes.includes(recurso.nome)),
        },
        {
          grupo: { nome: 'cadastro', descricao: 'Cadastros' },
          recursos: [
            { nome: 'certificadodigital', descricao: 'Certificados digitais' },
            { nome: 'usuario', descricao: 'Usuários' },
            { nome: 'empresa', descricao: 'Empresas' },
          ].filter(recurso => permissoes.includes(recurso.nome)),
        },
        {
          grupo: { nome: 'configuracao', descricao: 'Configuração' },
          recursos: [
            { nome: 'perfil_empresa', descricao: 'Perfil da empresa' },
            { nome: 'emailconfig', descricao: 'SMTP' },
            { nome: 'parametro_sistema', descricao: 'Parâmetros do sistema' },
          ].filter(recurso => permissoes.includes(recurso.nome)),
        },
        {
          grupo: { nome: 'lojaVirtual', descricao: 'Loja Virtual' },
          recursos: [
            { nome: 'plano', descricao: 'Plano' },
            { nome: 'aquisicao_credito', descricao: 'Aquisição de créditos' },
            { nome: 'relatorio_consumo', descricao: 'Relatório de consumo' },
          ].filter(recurso => permissoes.includes(recurso.nome)),
        },
      ].filter(grupo => grupo.recursos.length > 0)
      return gruposRecursos
    },
    buscarUsuarioInterno() {
      api.getPermissoesUsuario()
    },
    administradorSelecionado(checked) {
      this.recursos = []
      this.grupos.forEach(each => {
        this.recursos.push(...each.recursos.map(inMap => inMap.nome))
      })
      this.isAdm = checked
      this.$emit('administrador', checked)
    },
    selecionadoRecurso(selecionados) {
      this.recursos = selecionados
      this.$emit('input', this.recursos)
      this.alterarStatusAdministrador()
    },
    selecionadoGrupo(selecionados) {
      this.recursos = selecionados
      this.$emit('input', this.recursos)
      this.alterarStatusAdministrador()
    },
    alterarStatusAdministrador() {
      if (this.isAdm) {
        this.isAdm = !this.isAdm
        this.$emit('administrador', this.isAdm)
      }
    },
  },
}
</script>
